import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire Mobile App Designer`,
    text: "Employ the Best Mobile Design Experts",
    content: "Hire mobile app designer from Octet to execute your idea with the ideal combination of user experiences to design innovative applications with unparalleled capabilities.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire Mobile App Designer',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire Mobile App Developers for Upscaled Projects',
    text: "Our app developers for hire are ready to create stunning mobile app designs that will grow and deliver measurable results.",
    list: [
      {
        title: 'Professional and Unique Design',
        text: 'Hire mobile app developer who will bring skill and creativity to your mobile apps, ensuring distinct and polished designs.'
      },
      {
        title: 'Enhance the User Experience',
        text: 'Our app designers for hire understand how to structure material, create user-friendly interfaces, and use responsive design to guarantee that the design mobile app functions effortlessly across all platforms.'
      },
      {
        title: 'Performance Optimization',
        text: 'Our app developers for hire create mobile apps with clean code, fast loading speeds, and mobile-friendly, all of which are important for search engine ranking.'
      }
    ],
    image: ["hire-content-mobile.webp"],
    titleText: 'Mobile App Design',
    alt: 'Diverse Mobile app designs to upscale your projects'
  }
  const commonContent2 = {
    title: 'Get Octet’s Top App Developer for Hire',
    text: "Leading companies trust Octet Design Studio to Hire mobile UI designer to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire App Developer In 48 Hours',
        text: 'Our top app developers for hire ensure swift recruitment processes, bringing aboard and hiring the best mobile app designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our app designer for hire to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our app designer for hire commits 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Hire app developers and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-mobile.webp"],
    titleText: 'Best Mobile App Designers',
    alt: 'Hire the best mobile app designers from Octet'
  }
  const commonContent3 = {
    title: `3 Steps to Hire App Designer`,
    text: "Experience the Octet advantage by hiring app designers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: "Provide us with your project requirements, and we will share the resumes of our mobile app designers who best match your needs and business goals."
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled mobile application developer to choose the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire mobile app designer, onboard them in the next 48 hours and dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-mobile.webp"],
    titleText: 'Hire Mobile App Designer',
    alt: 'Simple steps to hire mobile app designers from Octet'
  }
  // const commonContent4 = {
  //   title: 'Hire Designers As Per Your Requirement',
  //   text: "At Octet, you have the freedom to choose your designer with flexible recruitment options. Select the hiring model that best suits your needs.",
  //   list: [
  //     {
  //       title: 'Hire on Fixed Cost Basis',
  //       text: "Get your project completed within a predetermined budget, ensuring cost-effectiveness and transparency."
  //     },
  //     {
  //       title: 'Hire on Time & Material Basis',
  //       text: "Flexibility is key with this model, pay for the actual hours worked and materials used, ideal for dynamic projects."
  //     },
  //     {
  //       title: 'Hire as a Full-time',
  //       text: "Secure a dedicated designer for ongoing projects, providing consistent support and expertise as part of your team."
  //     },
  //   ],
  //   image: ["hire-content-4.webp"],
  //   titleText: 'Hire UI UX Designer Flexibly',
  //   alt: 'Flexible Hiring Models to Hire UI UX Designers from our design agency'
  // }
  const cta = {
    title: `Expand your business faster with Mobile App Designers`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce Mobile App Designers",
      content: "Our mobile app designers use various tools and software to create designs that meet users' needs and help convert visitors into customers. Hire app designer to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "Mobile App Developer for SaaS ",
      content:
        "SaaS mobile app developer specializes in SaaS platforms to create visually appealing and user-friendly designs for web and mobile applications. Hire android app developer who will be a creative problem solver to create beautiful and functional designs that enhance the user experience.",
    },
    {
      title: "Enterprise Mobile App Designer",
      content: "Hire app UI designer who will allow you to deliver scalable solutions for large-scale operations. These designers will address intricate workflows and compliance needs with precision and user-centric design. Our app designer for hire will work closely with developers and product managers to ensure the final mobile app design meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire Mobile App Designer for Top-Tier Expertise`,
    des: `With value-driven and user-focused app designers for hire, you can shrewdly overcome the fierce market rivalry and offer your company a significant boost.`,
    tabs: ["Native App Designing ", "Cross-Platform App Designing", "Hybrid App Designing", "Responsive App Designing"],
    slides: [
      {
        title: 'Native App Designing',
        desc1: "Hire IoS designer to design apps specifically for Apple devices, following Apple's Human Interface Guidelines to ensure consistency with the iOS ecosystem.",
        desc2: `Hire android designers to design apps specifically for Android devices, following Google's Material Design guidelines for a cohesive user experience across the Android platform.`,
        list: [
          'Experienced in Android Mobile Applications',
          'Expertise in IoS Mobile Application',
          'Quick Turn-Around Time'
        ]
      },
      {
        title: 'Cross-Platform App Designing',
        desc1: "Our app designer for hire designs apps that can run on both iOS and Android using the React Native framework, which allows for a shared codebase while maintaining a native look and feel.",
        desc2: "Our mobile app designers design mobile apps using Google's Flutter framework, which enables cross-platform development with a single codebase and offers rich UI components and animations.",
        list: [
          'Experienced in React Native Designs',
          'Expertise in Flutter Designs',
          'Delivering Enriched Design Experience'
        ]
      },
      {
        title: 'Hybrid App Designing',
        desc1: "Hire app UI designer who combines elements of both native and web apps, typically using frameworks like Ionic or Cordova.",
        desc2: 'Our mobile application designer creates hybrid apps to run within a web view and can access device features through plugins.',
        list: [
          'Exceptional Hybrid Mobile App Designs',
          'Delivering Accessible Designs',
          'Providing Enhancing User-Experience'
        ]
      },
      {
        title: 'Responsive App Designing',
        desc1: "Hire mobile UI designer who focuses on creating applications that provide an optimal user experience across various devices and screen sizes.",
        desc2: "Our mobile app designers ensure that the app's interface and functionality adapt seamlessly to smartphone, tablet, or desktop use.",
        list: [
          'Expertise in Responsive UI Designs',
          'Providing Adaptive UX Designs',
          'Delivering Cross-platform Accessibility'
        ]
      }
    ],
    altOne: 'Native App Designing',
    titleOne: 'Native App Designing',
    altTwo: 'Cross-Platform App Designing',
    titleTwo: 'Cross-Platform App Designing',
    altThree: 'Hybrid App Designing',
    titleThree: 'Hybrid App Designing',
    altFour: 'Responsive App Designing',
    titleFour: 'Responsive App Designing',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const lastLength = projectData;
  const itemsList = [
    {
      title: '200+',
      text: 'Successful Projects '
    },
    {
      title: '30+',
      text: 'Experienced Designers'
    },
    {
      title: '80+',
      text: 'Satisfied Clients'
    }
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Hire app designer from us who are intensely passionate and committed to creating captivating designs and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'Mobile App Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you Hire App Developers from Octet?",
    "How can you Hire Mobile App Developers from Octet?",
    "Which companies Hire Android App Developer?",
    "Does your App Developers for Hire come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of mobile app developer who specialize in crafting engaging and functional interfaces.`,
        `Our app developers for hire have a proven track record of delivering user-centric designs across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `You should hire mobile app designer from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring app developers from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific design preferences or technical requirements. This will help us find the most suitable mobile application designer to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of mobile app designer and developers who match your project needs.`,
        `You can then interview and evaluate our mobile app developer to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring mobile UI designer from us, onboard our designers onto your project team and provide them access to necessary resources.`,
      ]
    },
    {
      para: [
        `Mobile app developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether design mobile app, websites, software interfaces, or consumer electronics, our app designers for hire can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Hiring mobile app designers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. `,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring mobile application designer from us, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire Website Developer for the best Quality Ratio",
    para: 'Our trained mobile app designers have expertise in various scalable and secure mobile app solutions for niche and global businesses.',
    content: [
      {
        title: "User Research",
        text: "To achieve sustained growth, consider hiring mobile app designer who can thoroughly analyze the behavior of your target audience. By gaining insights into their preferences and habits, our app designer for hire can create intuitive and effective solutions that cater to their needs.",
      },
      {
        title: "UI UX Design",
        text: "Our mobile application designer crafts visually appealing and intuitive interfaces, including layout, colors, typography, and interactive elements. Our designers also focus on the app's aesthetics and branding, ensuring it aligns with the brand's identity.",
      },
      {
        title: "Prototyping and Wireframing",
        text: "Hire app designer to create basic sketches or digital outlines of the app's layout to plan the structure and navigation. Our app designer for hire develops interactive models of the app to simulate user interactions and gather feedback early in the design process.",
      },
      {
        title: "Usability Testing",
        text: "Discover the specific benefits of website usability testing services. Hire app developers to uncover hidden usability issues, improve procedures, and raise user experience to enhance your digital presence.",
      },
      {
        title: "Cross Platform Development",
        text: "Hire mobile app developers to implement the UI UX design into a functional interface, focusing on responsiveness and performance. Our mobile app developer builds server-side logic, database management, and API integration to support the app’s functionality.",
      },
      {
        title: "Design Audit",
        text: "A mobile application designer thoroughly assesses your design elements, interaction flows, and user journey before making actionable recommendations to improve usability and drive business success.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you Hire App Developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of mobile app developer who specialize in crafting engaging and functional interfaces. 
          
          Our app developers for hire have a proven track record of delivering user-centric designs across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          You should hire mobile app designer from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you Hire Mobile App Developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring app developers from Octet involves just 3 easy steps:
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific design preferences or technical requirements. This will help us find the most suitable mobile application designer to meet your needs and expectations.
          
          Interview Designers:
          Once we understand your needs, we will share a list of mobile app designer and developers who match your project needs.
          You can then interview and evaluate our mobile app developer to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          After hiring mobile UI designer from us, onboard our designers onto your project team and provide them access to necessary resources."
              }
            },{
              "@type": "Question",
              "name": "Which companies Hire Android App Developer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Mobile app developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether design mobile app, websites, software interfaces, or consumer electronics, our app designers for hire can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Does your App Developers for Hire come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring mobile app designers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          
          
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring mobile application designer from us, you can trust that you're getting the most value for your money."
              }
            }]
          }                                            
        `}
      </script>
    </Helmet>
      <Layout hire="Hire Our Mobile App Designers">
        <div>
          <HireBanner data={banner} pageName="Hire Mobile App Designer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Industry's Top App Designer for Hire`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          {/* <ContentCommon data={commonContent4} big /> */}
          {/* <OurWorkslef data={ourData} /> */}
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our mobile app developer, hiring mobile app developers, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire Mobile App Designer | App Designer For Hire"
    description="Check out Octet’s top-notch mobile app designers selected carefully to ensure quality, with outcomes assured. Hire Mobile App Designer today!"
  />
);